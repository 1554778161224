import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

// import Main from 'styles/mediaKit';
import 'styles/mediaKit.scss';

const PrivacyPolicy = ({ location }) => (
  <Layout location={location}>
    <Meta
      title="Media Kit | ruttl 🔊"
      description="ruttl Media Kit - visual feedback and collaboration tool to leave comments on live websites, web apps, mobile apps and more."
      url="https://ruttl.com/media-kit/"
    />
    <main className="mediakit-styled-main">
      <div className="media-hero center">
        <div className="container">
          <h1>Logos and Branding</h1>
          <p>Because you shouldn&apos;t have to get our logo from Google</p>
          <a href="/assets/img/ruttl-media-kit-new.zip" className="button">
            Download media kit
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 512 512">
              <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="48"
                d="M268 112l144 144-144 144M392 256H100"></path>
            </svg>
          </a>
        </div>
      </div>

      <section className="downloads-section">
        <div className="container">
          <h2>Downloads</h2>
          {[
            {
              id: 0,
              title: 'Logo.png',
              format: 'PNG',
              zipLink: '/assets/img/all-png.zip',
              logoList: [
                {
                  id: 0,
                  logo: '/assets/img/media-kit/png/logo-white.png',
                  link: '/assets/img/media-kit/png/logo-white.png',
                  alt: 'ruttl-logo-white-png',
                },
                {
                  id: 1,
                  logo: '/assets/img/media-kit/png/logo-beige.png',
                  link: '/assets/img/media-kit/png/logo-beige.png',
                  alt: 'ruttl-logo-beige-png',
                },
                {
                  id: 2,
                  logo: '/assets/img/media-kit/png/logo-grey.png',
                  link: '/assets/img/media-kit/png/logo-grey.png',
                  alt: 'ruttl-logo-grey-png',
                },
                {
                  id: 3,
                  logo: '/assets/img/media-kit/png/logo-blue-1.png',
                  link: '/assets/img/media-kit/png/logo-blue-1.png',
                  alt: 'ruttl-logo-blue-1-png',
                },
                {
                  id: 4,
                  logo: '/assets/img/media-kit/png/logo-blue-2.png',
                  link: '/assets/img/media-kit/png/logo-blue-2.png',
                  alt: 'ruttl-logo-blue-2-png',
                },
                {
                  id: 5,
                  logo: '/assets/img/media-kit/png/logo-blue-3.png',
                  link: '/assets/img/media-kit/png/logo-blue-3.png',
                  alt: 'ruttl-logo-blue-3-png',
                },
                {
                  id: 6,
                  logo: '/assets/img/media-kit/png/logo-black.png',
                  link: '/assets/img/media-kit/png/logo-black.png',
                  alt: 'ruttl-logo-black-png',
                },
                {
                  id: 7,
                  logo: '/assets/img/media-kit/png/logo-red.png',
                  link: '/assets/img/media-kit/png/logo-red.png',
                  alt: 'ruttl-logo-red-png',
                },
                {
                  id: 8,
                  logo: '/assets/img/media-kit/png/logo-yellow.png',
                  link: '/assets/img/media-kit/png/logo-yellow.png',
                  alt: 'ruttl-logo-yellow-png',
                },
              ],
            },
            {
              id: 1,
              title: 'Logo.jpg',
              format: 'JPG',
              zipLink: '/assets/img/all-jpg.zip',
              logoList: [
                {
                  id: 0,
                  logo: '/assets/img/media-kit/jpg/logo-white.jpg',
                  link: '/assets/img/media-kit/jpg/logo-white.jpg',
                  alt: 'ruttl-logo-white-jpg',
                },
                {
                  id: 1,
                  logo: '/assets/img/media-kit/jpg/logo-beige.jpg',
                  link: '/assets/img/media-kit/jpg/logo-beige.jpg',
                  alt: 'ruttl-logo-beige-jpg',
                },
                {
                  id: 2,
                  logo: '/assets/img/media-kit/jpg/logo-grey.jpg',
                  link: '/assets/img/media-kit/jpg/logo-grey.jpg',
                  alt: 'ruttl-logo-grey-jpg',
                },
                {
                  id: 3,
                  logo: '/assets/img/media-kit/jpg/logo-blue-1.jpg',
                  link: '/assets/img/media-kit/jpg/logo-blue-1.jpg',
                  alt: 'ruttl-logo-blue-1-jpg',
                },
                {
                  id: 4,
                  logo: '/assets/img/media-kit/jpg/logo-blue-2.jpg',
                  link: '/assets/img/media-kit/jpg/logo-blue-2.jpg',
                  alt: 'ruttl-logo-blue-2-jpg',
                },
                {
                  id: 5,
                  logo: '/assets/img/media-kit/jpg/logo-blue-3.jpg',
                  link: '/assets/img/media-kit/jpg/logo-blue-3.jpg',
                  alt: 'ruttl-logo-blue-3-jpg',
                },
                {
                  id: 6,
                  logo: '/assets/img/media-kit/jpg/logo-black.jpg',
                  link: '/assets/img/media-kit/jpg/logo-black.jpg',
                  alt: 'ruttl-logo-black-jpg',
                },
                {
                  id: 7,
                  logo: '/assets/img/media-kit/jpg/logo-red.jpg',
                  link: '/assets/img/media-kit/jpg/logo-red.jpg',
                  alt: 'ruttl-logo-red-jpg',
                },
                {
                  id: 8,
                  logo: '/assets/img/media-kit/jpg/logo-yellow.jpg',
                  link: '/assets/img/media-kit/jpg/logo-yellow.jpg',
                  alt: 'ruttl-logo-yellow-jpg',
                },
              ],
            },
            {
              id: 2,
              title: 'Logo.svg',
              format: 'SVG',
              zipLink: '/assets/img/all-svg.zip',
              logoList: [
                {
                  id: 0,
                  logo: '/assets/img/media-kit/svg/logo-white.svg',
                  link: '/assets/img/media-kit/svg/logo-white.svg',
                  alt: 'ruttl-logo-white-svg',
                },
                {
                  id: 1,
                  logo: '/assets/img/media-kit/svg/logo-beige.svg',
                  link: '/assets/img/media-kit/svg/logo-beige.svg',
                  alt: 'ruttl-logo-beige-svg',
                },
                {
                  id: 2,
                  logo: '/assets/img/media-kit/svg/logo-grey.svg',
                  link: '/assets/img/media-kit/svg/logo-grey.svg',
                  alt: 'ruttl-logo-grey-svg',
                },
                {
                  id: 3,
                  logo: '/assets/img/media-kit/svg/logo-blue-1.svg',
                  link: '/assets/img/media-kit/svg/logo-blue-1.svg',
                  alt: 'ruttl-logo-blue-1-svg',
                },
                {
                  id: 4,
                  logo: '/assets/img/media-kit/svg/logo-blue-2.svg',
                  link: '/assets/img/media-kit/svg/logo-blue-2.svg',
                  alt: 'ruttl-logo-blue-2-svg',
                },
                {
                  id: 5,
                  logo: '/assets/img/media-kit/svg/logo-blue-3.svg',
                  link: '/assets/img/media-kit/svg/logo-blue-3.svg',
                  alt: 'ruttl-logo-blue-3-svg',
                },
                {
                  id: 6,
                  logo: '/assets/img/media-kit/svg/logo-black.svg',
                  link: '/assets/img/media-kit/svg/logo-black.svg',
                  alt: 'ruttl-logo-black-svg',
                },
                {
                  id: 7,
                  logo: '/assets/img/media-kit/svg/logo-red.svg',
                  link: '/assets/img/media-kit/svg/logo-red.svg',
                  alt: 'ruttl-logo-red-svg',
                },
                {
                  id: 8,
                  logo: '/assets/img/media-kit/svg/logo-yellow.svg',
                  link: '/assets/img/media-kit/svg/logo-yellow.svg',
                  alt: 'ruttl-logo-yellow-svg',
                },
              ],
            },
          ].map(({ id, title, format, zipLink, logoList }) => (
            <div
              className="single-download-block flex align-items-center"
              key={id}>
              <h3>{title}</h3>

              <div className="flex flex-wrap ">
                {logoList.map((i) => (
                  <a
                    id={i.id}
                    href={i.link}
                    className="logo-col image-gap"
                    target="_blank"
                    rel="noopener noreferrer">
                    <div
                      className="rect-logo-box flex align-items-center justify-content-center"
                      style={{
                        border: '1px solid rgba(10,20,74,0.1)',
                      }}>
                      <img src={i.logo} alt={i.alt} />
                    </div>
                  </a>
                ))}

                <a href={zipLink} className="button download-button-align">
                  Download All&nbsp;
                  {format}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg>
                </a>
              </div>
            </div>
          ))}

          {[
            {
              id: 0,
              title: 'Icon.png',
              iconOne: '/assets/img/media-kit/icon/icon-color.png',
              iconOneLink: '/assets/img/media-kit/icon/icon-color.png',
              altOne: 'ruttl-icon-png',
            },
            {
              id: 1,
              title: 'Icon.jpg',
              iconOne: '/assets/img/media-kit/icon/icon-color.jpg',
              iconOneLink: '/assets/img/media-kit/icon/icon-color.jpg',
              altOne: 'ruttl-icon-jpg',
            },
            {
              id: 2,
              title: 'Icon.svg',
              iconOne: '/assets/img/media-kit/icon/icon-color.svg',
              iconOneLink: '/assets/img/media-kit/icon/icon-color.svg',
              altOne: 'ruttl-icon-svg',
            },
          ].map(({ iconOne, id, title, iconOneLink, altOne }) => (
            <div
              className="single-download-block flex align-items-center"
              key={id}>
              <h3>{title}</h3>

              <div className="flex">
                <a
                  href={iconOneLink}
                  className="logo-col icon-col"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div
                    className="rect-logo-box flex align-items-center justify-content-center"
                    style={{
                      border: '1px solid rgba(10,20,74,0.1)',
                    }}>
                    <img src={iconOne} alt={altOne} />
                  </div>
                </a>
              </div>
            </div>
          ))}

          <div className="single-download-block flex align-items-center">
            <h3>Video</h3>
            <div className="flex">
              <div className="rect-logo-box flex flex-wrap align-items-center justify-content-center">
                <img
                  src="/assets/img/media-kit/ph-banners-edit-content.gif"
                  alt="PH edit content banner"
                />
                <a
                  href="/assets/img/ruttl-videos.zip"
                  className="button download-button-align">
                  Download All Videos
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="colors-section">
        <div className="container">
          <h2>Our colours</h2>

          <div className="flex align-items-center">
            <h3>Primary</h3>
            <div className="flex align-items-center colors-block-container">
              <div
                className="single-color-block flex align-items-center justify-content-center"
                style={{ backgroundColor: '#FFBD1C' }}>
                #FFBD1C
              </div>
              <div
                className="single-color-block flex align-items-center justify-content-center"
                style={{ backgroundColor: '#FF5040' }}>
                #FF5040
              </div>
              <div
                className="single-color-block flex align-items-center justify-content-center"
                style={{ backgroundColor: '#6E46FF' }}>
                #6E46FF
              </div>
            </div>
          </div>
          <div className="flex align-items-center">
            <h3>Secondary</h3>
            <div className="flex flex-wrap align-items-center colors-block-container">
              <div
                className="single-color-block-secondary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#F4F3EF' }}>
                #F4F3EF
              </div>
              <div
                className="single-color-block-secondary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#FFBD1C' }}>
                #FFBD1C
              </div>
              <div
                className="single-color-block-secondary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#F9ACAC' }}>
                #F9ACAC
              </div>
              <div
                className="single-color-block-secondary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#F45923' }}>
                #F45923
              </div>
              <div
                className="single-color-block-secondary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#0FB8BC' }}>
                #0FB8BC
              </div>
              <div
                className="single-color-block-secondary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#595AF3' }}>
                #595AF3
              </div>
            </div>
          </div>

          <div className="flex align-items-center">
            <h3>Tertiary</h3>
            <div className="flex flex-wrap align-items-center colors-block-container">
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#E8E3E5' }}>
                #E8E3E5
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#F18E1A' }}>
                #F18E1A
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#F78B8B' }}>
                #F78B8B
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#DC3718' }}>
                #DC3718
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#028E8E' }}>
                #028E8E
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#3A42DB' }}>
                #3A42DB
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#EA5960' }}>
                #EA5960
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#820000' }}>
                #820000
              </div>
              <div
                className="single-color-block-tertiary flex align-items-center justify-content-center"
                style={{ backgroundColor: '#2D2364' }}>
                #2D2364
              </div>
            </div>
          </div>

          {/* <div
              className="single-color-block"
              style={{ backgroundColor: colors.red }}>
              #FF5140
            </div>
            <div
              className="single-color-block"
              style={{ backgroundColor: colors.black }}>
              #160647
            </div> */}
        </div>
      </section>

      <section className="banner-thumbnails">
        <div className="container">
          <h2>Banners and Thumbnails</h2>
          <div className="flex">
            <div className="single-banner-col">
              <img
                src="/assets/img/media-kit/banner-1.jpg"
                alt="ruttl-banner-png"
              />

              <a target="_blank" href="/assets/img/media-kit/banner-1.jpg">
                Download
              </a>
            </div>
            <div className="single-banner-col">
              <img
                src="/assets/img/media-kit/banner-2.jpg"
                alt="ruttl-banner-jpg"
              />

              <a target="_blank" href="/assets/img/media-kit/banner-2.jpg">
                Download
              </a>
            </div>
            <div className="single-banner-col">
              <img
                src="/assets/img/media-kit/banner-3.jpg"
                alt=" ruttl-banner-thumbnail"
              />

              <a target="_blank" href="/assets/img/media-kit/banner-3.jpg">
                Download
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="about-ruttl">
        <div className="container">
          <h2>About ruttl</h2>
          <p>
            ruttl is a feedback tool for live websites. Just like Invision which
            is a feedback tool for static websites, ruttl allows you to review
            live links or stagging links.
          </p>
          <p>
            Current Problem: Currently, in order to review developed websites,
            people take screenshots and encircle the issues, and share a word
            doc with the developer. Alternatively, they just sit with the
            developers and ask them to make the changes. But these processes are
            very tedious and time taking. On an average, a single page takes
            around 3-4 hours to review and still, the final output is just close
            to perfection. That’s why we created ruttl. ruttl allows you to
            leave comments on live websites and make real time edits so you can
            give precise, contextual feedback to your team.
          </p>

          <Link to="/about/" className="button">
            Read our story
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 512 512">
              <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="48"
                d="M268 112l144 144-144 144M392 256H100"></path>
            </svg>
          </Link>
        </div>
      </section>
    </main>
  </Layout>
);

PrivacyPolicy.propTypes = {
  location: PropTypes.object,
};

export default PrivacyPolicy;
